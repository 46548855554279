import React, { useState, useEffect } from "react";
import axios from "axios";
import { admin_url } from "../../../config/config";

export default function TermsAndConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState([]);

  useEffect(() => {
    axios.get(admin_url + "/cms/get").then((response) => {
      setTermsAndConditions(response.data.data.termsAndConditions);
    });
  }, []);

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginTop: "3rem",
          fontFamily: "sans-serif",
          fontSize: "3rem",
        }}
      >
        Terms & Conditions
      </h1>
      <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
        {termsAndConditions}
      </div>
    </>
  );
}
